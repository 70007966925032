<template>
  <div class="container-fluid m-0 p-0">
    <div class="row m-0 p-3">
      <div class="col-12 m-0 p-3 shadow mb-3 bg-white rounded">
        <div class="row m-0 p-0">
          <div class="col-12 col-xl-6 mx-0 mb-3 mb-xl-0">
            <p class="font-1-2s font-600">Attribute Products Details</p>
          </div>
          <div class="col-12 col-xl-6 text-right ">
            <button
              type="button"
              class="btn btn-sm bt-main  px-3"
              @click="goto()"
            >
              Back
            </button>
          </div>
        </div>
        <!-- ---------------------- -->
        <div class="row m-0 p-0 align-items-center">
          <div class="col-6 col-xl-2">
            <img
              v-if="product.length > 0 && product[0].img !== ''"
              :src="`${$cons.API_URL_IMG}${product[0].img}`"
              class="img-fluid img-products"
              alt=""
            />
            <img
              v-else
              src="@/assets/photo.png"
              class="img-fluid w-100"
              alt=""
            />
          </div>
          <div class="col-8" v-if="product.length > 0">
            <p class="font-1-2s text-main font-600">
              {{ product[0].prNameTH }}
            </p>
            <p>
              SKU <span class=" text-main mx-1">{{ product[0].skCode }}</span>
            </p>
          </div>
          <div class="col-12 text-right" v-if="InitialAttributeBT">
            <button
              type="button"
              class="btn btn-sm bt-twd-outline mx-1"
              @click="InitialAttribute()"
            >
              Initial Attribute
            </button>
          </div>
        </div>
        <div
          class="row m-0 p-0"
          v-if="
            attributeproductlist.length > 0 && !!attributeproductlist[0].CLASS
          "
        >
          <div
            class="col-12 m-0 p-0"
            v-for="(e, i) in attributeproductlist"
            :key="i"
          >
            <UpdateProductAttribute
              :key="rekey + e.CLASS"
              :catWeb="e.CLASS"
              :skCode="e.skCode"
              @return_Action="get_data_production"
              @notYetMap="notYetMap"
            />
          </div>
        </div>
        <!-- +--------------------------- -->
      </div>
    </div>
  </div>
</template>

<script>
import UpdateProductAttribute from "@/components/Manage/Attributes/UpdateProductAttribute.vue";
export default {
  name: "AttributesProductsDetail",
  components: { UpdateProductAttribute },
  data() {
    return {
      rekey: Date.now() + 5,
      product: [],
      InitialAttributeBT: false,
      InitialAttributeBTList: [],
      attributeproductlist: [],
    };
  },
  mounted() {
    this.get_data_production();
  },
  methods: {
    notYetMap(v) {
      console.log(`notYetMap : ${v}`);
      this.InitialAttributeBTList.push(v);
      // console.log(this.InitialAttributeBTList);
      let checkValincat = this.InitialAttributeBTList.some((e) => e === false);
      // console.log(checkValincat);

      if (checkValincat) {
        this.InitialAttributeBT = false;
      } else {
        this.InitialAttributeBT = true;
      }
    },
    async InitialAttribute() {
      try {
        let mss = `คุณแน่ใจหรือไม่ว่าคุณตั้งค่าแอตทริบิวต์เริ่มต้น อาจส่งผลต่อแอตทริบิวต์เก่าที่แมปแล้ว / Are you sure you set the default attribute? May affect old attributes that have already been mapped.`;
        let confirm = await this.$serviceMain.showConfirmAlert(this, mss);
        if (confirm) {
          let data = { skCode: this.$route.params.skcode };
          // console.log(data);
          let getAPI = await this.$serviceAPI.call_API(
            "post",
            `attribute/ImportAttributeFromWebControlBySkCode`,
            data,
            1
          );
          this.$serviceMain.showSuccessAlert(this, "สำเร็จ / Complete");

          this.get_data_production();
        }
      } catch (error) {
        console.log(error);
        this.$serviceMain.showErrorAlert(
          this,
          error.message == undefined ? error : error.message
        );
      }
    },
    goto() {
      if (!!this.$route.query?.content) {
        this.$router.push({
          name: "Enrich Product Detail",
          params: { skcode: this.$route.params.skcode },
        });
      } else {
        this.$router.push({ name: "Attributes-Products" });
      }
    },

    async get_data_production() {
      try {
        this.InitialAttributeBTList = [];
        let data = {
          mode: "skcode",
          find: this.$route.params.skcode,
          catCode: "",
          notmapping: "",
        };

        let getAPI = await this.$serviceAPI.call_API(
          "post",
          `attribute/get_attribute_porduct_list`,
          data,
          1
        );
        // console.log(getAPI.data);
        this.attributeproductlist = getAPI.data;
        let _data = getAPI.data;
        if (_data.length > 0) {
          let check_null_image = await this.$serviceMain.chechnull(
            _data[0]?.images
          );

          let img =
            check_null_image === "" ? "" : String(_data[0]?.images).split("|");

          img = img[0] !== "" ? img[0] : img[1] !== "" ? img[1] : "";

          _data[0].img = img;

          this.product = _data;
          this.rekey = Date.now(0);

          let check_class = this.attributeproductlist[0].CLASS;

          if (!!!check_class) {
            throw `สินค้านี้ยังไม่ได้ผูก catweb / This product not mapping catweb`;
          }
        }
      } catch (error) {
        console.log(error);
        this.$serviceMain.showErrorAlert(
          this,
          error.message == undefined ? error : error.message
        );
      }
    },
  },
  async beforeRouteLeave(to, from, next) {
    // const answer = window.confirm(
    //   "คุณต้องการที่ออกหน้านี้? คุณมีการเปลี่ยนแปลงที่ยังไม่ได้บันทึก! / Do you really want to leave? you have unsaved changes!"
    // );
    let confirm = await this.$serviceMain.showConfirmAlert(
      this,
      "คุณต้องการที่ออกหน้านี้? คุณมีการเปลี่ยนแปลงที่ยังไม่ได้บันทึก! / Do you really want to leave? you have unsaved changes!"
    );
    if (confirm) {
      next();
    } else {
      next(false);
    }
  },
};
</script>

<style scoped>
.img-products {
  width: 150px;
  height: auto;
}
</style>
