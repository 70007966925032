<template>
  <div class="row mx-0 mb-3 p-0" :key="rekey">
    <div class="col-12">
      <hr />
      <p class="font-600">
        {{ `${labelAttrGroup} ( ${catWeb} )` }}
        <button
          v-if="attributelist.length !== 0"
          class="ml-2 btn btn-light"
          @click="goto_att_group(attributelist[0].attrGroupId)"
        >
          Edit Attribute Group
        </button>
      </p>
    </div>
    <div class="col-4">
      <p>ข้อมูลเฉพาะสินค้า</p>
      <hr />
      <div
        class="row p-1 font-0-9s "
        v-for="(e, i) in dataattr"
        :key="i"
        :class="even(i) ? '' : 'att-even'"
      >
        <!-- {{e}} -->
        <div class="col-6 font-600">{{ e.PropName }}</div>
        <div class="col-6">{{ e.PropDetail }}</div>
      </div>
    </div>
    <!-- --------------------- -->
    <div class="col-8  ">
      <div class="row m-0 p-0">
        <div
          class="col-12 col-lg-6"
          v-for="(e, i) in attributelist"
          :key="e.attrGroupId + i"
        >
          <div class="form-group form-group-sm">
            <label
              >{{ e.attrlabelTH }}
              <span
                v-if="String(e.required) === '1'"
                class="badge badge-warning"
                >Required</span
              ></label
            >
            <!-- :clear-on-select="true"
              :allow-empty="false" -->
            <multiselect
              :key="e.attrGroupId + i"
              v-model="selectattribute[i]"
              :options="e.option"
              :clear-on-select="true"
              :allow-empty="true"
              deselect-label=""
              select-label=""
              selected-label=""
              label="labelTH"
              track-by="labelTH"
            >
              <div
                class="option__desc isDisabled"
                slot="option"
                slot-scope="props"
              >
                <span>{{ props.option.labelTH }} </span>
              </div>
            </multiselect>
            <!-- <select
              class="form-control form-control-sm"
              v-model="selectattribute[i]"
            >
              <option
                v-for="(ee, ii) in e.option"
                :key="e.attrGroupId + i + ii"
                :value="ee"
                :disabled="String(ee.active) === '0'"
                >{{ ee.labelTH }}
              </option>
            </select> -->
            <!-- {{ selectattribute[i] }} -->
          </div>
        </div>
      </div>
      <div class="row m-0 p-0">
        <div class="col-12 my-3 text-right">
          <button
            :disabled="attributelist.length === 0"
            type="button"
            class="btn btn-sm bt-main px-4"
            @click="save()"
          >
            Save
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "UpdateProductAttribute",

  props: ["catWeb", "skCode"],
  data() {
    return {
      rekey: Date.now() + 5,
      labelAttrGroup: "",
      attributelist: [],
      selectattribute: [],
      dataattr: [],
    };
  },
  mounted() {
    this.get_data_attributes();
    this.getPage7();
  },
  methods: {
    async goto_att_group(val) {
      const routeData = this.$router.resolve({
        name: "Attribute-group-Edit",
        query: { attrGroupId: val },
      });
      window.open(routeData.href, "_blank");
    },
    even(i) {
      if (i % 2 == 0) {
        return true;
      } else {
        return false;
      }
    },
    async getPage7() {
      try {
        let data = { skCode: this.skCode, catWeb: this.catWeb };

        let getAPI = await this.$serviceAPI.call_API_NO_LOAD(
          "post",
          `attribute/get_page_seven_by_cat`,
          data,
          1
        );
        //  console.log(getAPI.data);
        this.dataattr = getAPI.data;
      } catch (error) {
        console.log(error);
        this.$serviceMain.showErrorAlert(
          this,
          error.message == undefined ? error : error.message
        );
        console.log(error);
      }
    },
    async get_data_attributes() {
      try {
        this.selectattribute = [];
        let data = { skuCode: this.skCode, catWeb: this.catWeb };

        let getAPI = await this.$serviceAPI.call_API(
          "post",
          `attribute/get_attribute_porduct_by_sku`,
          data,
          1
        );

        let arr = getAPI.data;
        // console.log(`${this.catWeb} :${JSON.stringify(arr[0])}}`);
        if (arr.length > 0) {
          this.labelAttrGroup = arr[0]?.labelAttrGroup;
        } else {
          this.$bvToast.toast(
            `Please Mapping Attribute in cat ${this.catWeb}`,
            {
              title: `Warning!`,
              variant: "warning",
              toaster: "b-toaster-top-right",
              solid: true,
              autoHideDelay: 30000,
            }
          );
        }

        arr.forEach((object) => {
          object["option"] = [];
        });

        this.$store.dispatch("loadingActions/onload");

        this.attributelist = arr;
        // console.log(this.attributelist);
        // ----- set data follow group ------
        for await (let v of this.attributelist) {
          // console.log(v);
          let { attrId, attrGroupId, attrlabelTH } = v;
          this.selectattribute.push("");
          let get_option = await this.get_data_attributes_option(attrId);
          let index = this.attributelist.findIndex((e) => e.attrId === attrId);
          // console.log(index);
          if (index !== -1) {
            let arr = get_option;

            arr.forEach((object) => {
              object["skCode"] = this.skCode;
              object["labelAttr"] = attrlabelTH;
              object["attrGroupId"] = attrGroupId;
              object["attrId"] = attrId;
              object["$isDisabled"] =
                String(object.active) === "0" ? true : false;
            });
            this.attributelist[index].option = arr;
            // this.attributelist[index].option = get_option;
          }
        }
        // ----- set data follow group ------
        //------------ get value select in DB ------------------
        await this.get_data_value_option();

        this.$store.dispatch("loadingActions/onunload");
        this.rekey = Date.now();
        // console.log(this.attributelist);
      } catch (error) {
        console.log(error);
        this.$serviceMain.showErrorAlert(
          this,
          error.message == undefined ? error : error.message
        );
      }
    },
    async get_data_attributes_option(attrId) {
      return new Promise(async (resolve, reject) => {
        try {
          let data = { attrId: attrId };

          let getAPI = await this.$serviceAPI.call_API_NO_LOAD(
            "post",
            `attribute/get_attribute_option`,
            data,
            1
          );

          resolve(getAPI.data);
        } catch (error) {
          console.log(error);
          this.$serviceMain.showErrorAlert(
            this,
            error.message == undefined ? error : error.message
          );
        }
      });
    },
    async get_data_value_option() {
      try {
        let data = { skuCode: this.skCode, catWeb: this.catWeb };

        let getAPI = await this.$serviceAPI.call_API_NO_LOAD(
          "post",
          `attribute/get_value_attribute_porduct_by_sku`,
          data,
          1
        );

        if (getAPI.data.length > 0) {
          let getdata = getAPI.data;
          for await (let a of getdata) {
            let { skCode, attrGroupId, attrDetail } = a;
            if (!!attrDetail) {
              let _attrDetail = JSON.parse(attrDetail);
              // console.log(this.attributelist);
              for await (let v of _attrDetail) {
                //   console.log(v);
                let { attrId, option_id } = v;

                let index_att = this.attributelist.findIndex(
                  (e) => e.attrId === attrId
                );
                //   console.log(index_att);
                if (index_att !== -1) {
                  let {
                    labelEN: attrlabelEN,
                    labelTH: attrlabelTH,
                    option,
                  } = this.attributelist[index_att];

                  let index_option = option.findIndex(
                    (e) => e.id === option_id
                  );
                  //    console.log(option[index_option]);
                  if (index_option !== -1) {
                    //  console.log(option[index_option]);

                    this.selectattribute[index_att] = {
                      ...option[index_option],
                    };
                  }
                }

                // console.log("option");
                // console.log(option);
              }
            }
          }
          this.$emit("notYetMap", false);
          //   console.log(this.selectattribute);
        } else {
          this.$emit("notYetMap", true);
        }
      } catch (error) {
        this.$serviceMain.showErrorAlert(
          this,
          error.message == undefined ? error : error.message
        );
        console.log(error);
      }
    },

    async validate() {
      return new Promise(async (resolve, reject) => {
        let data = this.selectattribute;

        let i = 0;
        for await (let v of data) {
          let check = !!!v;
          //   console.log(check);
          if (check) {
            // console.log(i);
            // console.log(this.attributelist[index].data[i]);
            let { required, attrlabelTH } = this.attributelist[i];
            if (String(required) === "1") {
              resolve(`กรุณาระบุ ${attrlabelTH}`);
            }
          }
          i++;
        }

        resolve("OK");
      });
    },
    async save() {
      try {
        // console.log(index);
        let vali = await this.validate();
        if (vali !== "OK") {
          throw vali;
        }
        let mss = `ยืนยันการบันทึก / Confirm Recording?`;
        let confirm = await this.$serviceMain.showConfirmAlert(this, mss);
        if (confirm) {
          // console.log(this.selectattribute);
          let result;

          result = this.selectattribute.map((element) => {
            if (element === null) {
              return "";
            } else {
              return element;
            }
          });
          // console.log(result);
          let getAPI = await this.$serviceAPI.call_API(
            "post",
            `attribute/update_product_attribute_value`,
            result,
            1
          );
          this.$serviceMain.showSuccessAlert(this, "สำเร็จ / Complete");
          //   this.get_data_attributes();
          this.$emit("return_Action");
        }
      } catch (error) {
        this.$serviceMain.showErrorAlert(
          this,
          error.message == undefined ? error : error.message
        );
        console.log(error);
      }
    },
  },
};
</script>

<style></style>
